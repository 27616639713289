import commonApiService from './commonApiService.js';

const getStatuses = async () => {
    try {
        const response = await commonApiService.apiPrivate().get('/status');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const setStatus = async (id, data) => {
    try {
        return await commonApiService.apiPrivate().put(`/status/${id}`, data);
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const createStatus = async (data) => {
    try {
        return await commonApiService.apiPrivate().post(`/status`, data);
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const statusService = {
    getStatuses,
    setStatus,
    createStatus,
};

export default statusService;