import React, {useEffect, useState} from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";
import {useParams} from "react-router-dom";
import activitiesService from "../../../services/activitiesService.js";
import utilsHelper from "../../../helpers/utilsHelper.js";
import statusService from "../../../services/statusService.js";

const Activity = () => {

    const {taskId} = useParams();
    const [data, setData] = useState({});
    const [dataStatuses, setDataStatus] = useState({});


    const [selectedStatus, setSelectedStatus] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (status) => {
        setSelectedStatus(status);
        setIsOpen(false);
    };

    useEffect(() => {
        activitiesService.getActivity(taskId).then((response) => {
            if (response.success) {
                setData(response.data);
                console.log(data);
            }
        });
        statusService.getStatuses().then(data => {
            if(data.success) {
                setDataStatus(data.data);
            }
        });
    }, [taskId]);

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                <div className="col-md-9 col-lg-10 p-4 content-container">
                    <h2 className="mb-4">{data.name}</h2>

                    <div className="row">
                        <div className="col-md-9 row height-min-content">

                            <div className="card-box">

                                <div className="task-status-container">
                                    <div className="task-status-row">

                                        <div className="task-select">
                                            <label htmlFor="status-selector">Estado:</label>
                                            <div className="custom-select">
                                                <div
                                                    className="select-header"
                                                    onClick={toggleDropdown}
                                                    style={{
                                                        backgroundColor: selectedStatus?.color || '#f0f0f0',
                                                        color: selectedStatus?.color ? '#fff' : '#000',
                                                    }}
                                                >
                                                    {selectedStatus?.name || '---'}
                                                </div>
                                                {isOpen && (
                                                    <ul className="select-options">
                                                        {Array.isArray(dataStatuses) &&
                                                            dataStatuses.map((status) => (
                                                                <li
                                                                    key={status.id}
                                                                    onClick={() => handleOptionClick(status)}
                                                                    style={{
                                                                        backgroundColor: status.color,
                                                                        color: '#fff',
                                                                    }}
                                                                >
                                                                    {status.name}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>

                                        <div className="task-hours">
                                            <label htmlFor="work-hours">Horas:</label>
                                            <input type="number" id="work-hours" min="0" step="0.1"
                                                   placeholder="Horas"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <textarea readOnly className="form-control form-control-lg" rows="5"
                                              value={data.description}></textarea>
                                </div>

                                <div className="comment-list-container">
                                    <div className="comment">
                                        <div className="user-info">
                                            <div className="user-avatar">
                                                <span className="initials">AB</span>
                                            </div>
                                            <div className="user-details">
                                                <h5 className="user-name">Ana Beltrán</h5>
                                                <p className="comment-date">Hace 2 horas</p>
                                            </div>
                                        </div>
                                        <p className="user-comment">Este es un comentario de prueba. ¡Adjunto una
                                            imagen!</p>
                                        <div className="attachments">
                                            <div className="image-attachment">
                                                <img src="https://portal.andina.pe/EDPfotografia3/Thumbnail/2019/04/10/000577436W.jpg" alt="Imagen adjunta"/>
                                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcnDhsiB7Aqg3MEBQf947-Upuwtm2RociIgg&s" alt="Imagen adjunta"/>
                                            </div>
                                            <div className="link-attachment">
                                                <a href="https://example.com" target="_blank">Visitar página web</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="comment">
                                        <div className="user-info">
                                            <div className="user-avatar">
                                                <span className="initials">CF</span>
                                            </div>
                                            <div className="user-details">
                                                <h5 className="user-name">Carlos Fernández</h5>
                                                <p className="comment-date">Hace 1 día</p>
                                            </div>
                                        </div>
                                        <p className="user-comment">Aquí dejo un comentario con un archivo PDF
                                            adjunto.</p>
                                        <div className="attachments">
                                            <div className="pdf-attachment">
                                                <i className="bi bi-file-earmark-pdf"></i>
                                                <a href="#">Archivo PDF adjunto</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="comment">
                                        <div className="user-info">
                                            <div className="user-avatar">
                                                <span className="initials">ML</span>
                                            </div>
                                            <div className="user-details">
                                                <h5 className="user-name">Martín López</h5>
                                                <p className="comment-date">Hace 3 días</p>
                                            </div>
                                        </div>
                                        <p className="user-comment">Aquí hay otro comentario sin adjuntos.</p>
                                    </div>
                                </div>


                                <div className="comment-box-container">
                                    <textarea id="comment-box" placeholder="Escribe un comentario..."></textarea>
                                    <div className="comment-actions">
                                        <button className="action-btn" title="Adjuntar PDF">
                                            <i className="bi bi-file-earmark-pdf"></i>
                                        </button>
                                        <button className="action-btn" title="Adjuntar Imagen">
                                            <i className="bi bi-image"></i>
                                        </button>
                                        <button className="action-btn" title="Adjuntar URL">
                                            <i className="bi bi-link"></i>
                                        </button>
                                        <button className="action-btn" title="Adjuntar Video">
                                            <i className="bi bi-camera-video"></i>
                                        </button>
                                        <button className="send-btn" title="Enviar">
                                            <i className="bi bi-send"></i> Enviar
                                        </button>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div className="col-md-3">
                            <div className="card-box">
                                <small>Creado por:</small>
                                <div className="profile">
                                    <div className="profile-avatar profile-avatar-reportado">
                                        {utilsHelper.shortenName(data.created_by_user?.name)}
                                    </div>
                                    <div className="profile-info">
                                        <p><strong>{data.created_by_user?.name}</strong></p>
                                    </div>
                                </div>
                            </div>

                            {/*
                            <div className="card-box">
                                <small>Responsables:</small>
                                <div className="profile">
                                    <div className="profile-avatar profile-avatar-responsable">
                                        AB
                                    </div>
                                    <div className="profile-info">
                                        <p><strong>Anastasia Barria</strong></p>
                                    </div>
                                </div>
                            </div>*/}

                            <div className="card-box">
                                <small>Asignado a:</small>
                                {Array.isArray(data.task_assigns) && data.task_assigns.map((task_assign) => (
                                    <div className="profile" key={task_assign.id}>
                                        <div className="profile-avatar profile-avatar-asignado">
                                            {utilsHelper.shortenName(task_assign.name)}
                                        </div>
                                        <div className="profile-info">
                                            <p><strong>{task_assign.name}</strong></p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="dates-container card-box">
                                <div className="date-item">
                                    <span className="date-label">Inicio Estimado:</span>
                                    <span
                                        className="date-value">{utilsHelper.formatDate(data.estimated_start, false)}</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Fin Estimado:</span>
                                    <span className="date-value">{utilsHelper.formatDate(data.estimated_end, false)}</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Inicio Real:</span>
                                    <span className="date-value">{utilsHelper.formatDate(data.actual_start, false)}</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Fin Real:</span>
                                    <span className="date-value">{utilsHelper.formatDate(data.actual_end, false)}</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Creado el:</span>
                                    <span className="date-value">{utilsHelper.formatDate(data.created_at, true)}</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Actualizado el:</span>
                                    <span className="date-value">{utilsHelper.formatDate(data.updated_at, true)}</span>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
}

export default Activity