import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from '../pages/public/Login.js';
import PrivateRoute from "./PrivateRoute.js";
import {AuthProvider} from "../AuthContext.js";
import Profile from "../pages/private/Profile/Profile.js";
import Users from "../pages/private/Users/Users.js";
import Roles from "../pages/private/Roles/Roles.js";
import CustomFields from "../pages/private/CustomFields/CustomFields.js";
import Status from "../pages/private/Status/Status.js";
import Projects from "../pages/private/Projects/Projects.js";
import Activities from "../pages/private/Activities/Activities.js";
import Loading from "../pages/public/Loading.js";
import Activity from "../pages/private/Activities/Activity.js";
import Clients from "../pages/private/Clients/Clients.js";

const AppRoutes = () => {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/roles" element={<PrivateRoute><Roles /></PrivateRoute>} />
                    <Route path="/municipios" element={<PrivateRoute><Clients /></PrivateRoute>} />
                    <Route path="/usuarios" element={<PrivateRoute><Users /></PrivateRoute>} />
                    <Route path="/usuarios/:clientId" element={<PrivateRoute><Users /></PrivateRoute>} />
                    <Route path="/campos-personalizados" element={<PrivateRoute><CustomFields /></PrivateRoute>} />
                    <Route path="/estados" element={<PrivateRoute><Status /></PrivateRoute>} />
                    <Route path="/proyectos" element={<PrivateRoute><Projects /></PrivateRoute>} />
                    <Route path="/perfil" element={<PrivateRoute><Profile /></PrivateRoute>} />
                    <Route path="/actividades" element={<PrivateRoute><Activities /></PrivateRoute>} />
                    <Route path="/actividad/:taskId" element={<PrivateRoute><Activity /></PrivateRoute>} />
                    <Route path="*" element={<Loading />} />
                </Routes>
            </AuthProvider>
        </Router>
    );
};

export default AppRoutes;
