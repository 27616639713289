import React, { useState, useEffect } from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";
import ModalStatus from "../Status/ModalStatus.js";
import {useParams} from "react-router-dom";
import statusService from "../../../services/statusService.js";
import utilsHelper from "../../../helpers/utilsHelper.js";

const Status = () => {

    const {clientId} = useParams();
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingStatus, setEditingStatus] = useState(null);
    const colorPalette = [
        '#e74c3c', '#2ecc71', '#3498db', '#f39c12', '#9b59b6', '#1abc9c',
        '#e67e22', '#34495e', '#95a5a6', '#d35400', '#16a085', '#2980b9',
        '#8e44ad', '#f1c40f', '#c0392b', '#7f8c8d'
    ];

    const loadContent = () => {
        statusService.getStatuses().then(data => {
            if(data.success) {
                setData(data.data);
            }
        });
    }

    useEffect(() => {
        loadContent();
    },[]);

    const handleCreate = () => {
        setEditingStatus(null);
        setIsModalOpen(true);
    };

    const handleEdit = (item) => {
        setEditingStatus(item);
        setIsModalOpen(true);
    };

    const handleDelete = () => {};

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                <div className="p-4 content-container col-md-9 col-lg-10">
                    <h2 className="mb-4">
                        <i className="bi bi-card-checklist"></i> Administración de Estados
                    </h2>
                    <button className="btn btn-success mb-3" onClick={handleCreate}>
                        Crear Nuevo Estado
                    </button>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Color</th>
                            <th>Fecha de creación</th>
                            <th>Fecha de actualización</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(data) && data.map((item) => (
                            <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>
                                    <div className="color-palette-circle color-palette-td" style={{backgroundColor: item.color}}></div>
                                </td>
                                <td>{utilsHelper.formatDate(item.created_at, true)}</td>
                                <td>{utilsHelper.formatDate(item.updated_at, true)}</td>
                                <td>
                                    <button
                                        className="btn btn-success btn-sm me-2"
                                        onClick={() => handleEdit(item)}
                                    >
                                        Editar
                                    </button>
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => handleDelete(item.id)}
                                    >
                                        Borrar
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {isModalOpen && (
                        <ModalStatus
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            editingStatus={editingStatus}
                            clientId={clientId}
                            loadContent={loadContent}
                            colorPalette={colorPalette}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Status;