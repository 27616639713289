import React, { useState } from 'react';
import { useAuth } from '../../AuthContext.js';
import authService from "../../services/authService.js";
import "./public.css";

function Login() {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        const data = await authService.login(username, password);
        if (data.error){
            setError(data.error);
        } else {
            await login(data.access_token);
        }
    };

    return (
        <div className="login-container d-flex align-items-center justify-content-center">
            <div className="card shadow-lg p-4">
                <div className="card-body">
                    <img src="/logo-rcj.png" className="logo-login-top" alt="Red Ciudad Joven"/>
                    <h3 className="text-center mb-4">Bienvenido</h3>
                    <p className="text-center text-muted mb-4">
                        Ingresa tus credenciales para iniciar sesión
                    </p>
                    {error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <label htmlFor="user" className="form-label">
                                Usuario
                            </label>
                            <input
                                type="text"
                                id="user"
                                className="form-control"
                                placeholder="Ingresa tu usuario"
                                value={username}
                                onChange={(e) => setUserName(e.target.value)}  // Cambié 'email' a 'user'
                                required
                            />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="password" className="form-label">
                                Contraseña
                            </label>
                            <input
                                type="password"
                                id="password"
                                className="form-control"
                                placeholder="Ingresa tu contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            <button type="submit" className="btn btn-primary w-100">
                                Iniciar sesión
                            </button>
                        </div>
                    </form>

                    <div className="text-center">
                        <img src="/logo-santiago.png" className="logo-login-bottom" alt="Red Ciudad Joven"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
