import React from 'react';
import {Link} from "react-router-dom";

const SidebarNav = () => {

    const logout = () => {
        if(window.confirm('¿Estás seguro que deseas cerrar la sesión?')){
            localStorage.clear();
            window.location.reload();
        }
    }

    return (
        <div className="col-md-3 col-lg-2 bg-dark text-white p-4 sidebar">
            <div className="logo-sidebar">
                <img src="/logo-rcj-white.png" className="logo-login-top" alt="Red Ciudad Joven"/>
            </div>
            <ul className="nav flex-column">
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/perfil">
                        <i className="bi bi-person-fill"></i> Mi Perfil
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/roles">
                        <i className="bi bi-menu-button-fill"></i> Roles
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/usuarios">
                        <i className="bi bi-people-fill"></i> Usuarios
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/campos-personalizados">
                        <i className="bi bi-bar-chart-steps"></i> Datos Personalizados
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/estados">
                        <i className="bi bi-card-checklist"></i> Estados
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/proyectos">
                        <i className="bi bi-briefcase"></i> Proyectos
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/actividades">
                        <i className="bi bi-list-task"></i> Actividades
                    </Link>
                </li>
            </ul>
            <button className="logout-btn btn w-100" onClick={logout}>Cerrar sesión</button>
            <div className="text-center">
                <img src="/logo-santiago.png" className="logo-login-bottom" alt="Red Ciudad Joven"/>
            </div>
        </div>
    );
}

export default SidebarNav;