import React, {useEffect, useState} from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";
import activitiesService from "../../../services/activitiesService.js";
import utilsHelper from "../../../helpers/utilsHelper.js";
import { useNavigate } from 'react-router-dom';

const Activities = () => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const loadContent = () => {
        activitiesService.getActivities().then(data => {
            setData(data);
        });
    }

    const goToActivity = (id) => {
        navigate(`/actividad/${id}`);
    }

    useEffect(() => {
        loadContent();
    }, []);

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                <div className="col-md-9 col-lg-10 p-4 content-container">
                    <h2 className="mb-4"><i className="bi bi-list-task"></i> Actividades</h2>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p-3 bg-light rounded shadow-sm">

                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Creado</th>
                                        <th>Nombre</th>
                                        <th>Estado</th>
                                        <th>Creado por</th>
                                        <th>Asignados</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Array.isArray(data) && data.map((item) => (
                                        <tr className="activity-item" key={item.id} onClick={() => goToActivity(item.id)}>
                                            <td>{utilsHelper.formatDate(item.created_at, false)}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                {item.status_id === null
                                                    ? <span className="badge bg-secondary">Sin estado</span>
                                                    : <span className="badge bg-secondary">{item.status.name}</span>}
                                            </td>
                                            <td>
                                                <div title={item.created_by_user.name}
                                                     className="profile-avatar profile-avatar-asignado profile-avatar-table">
                                                    {utilsHelper.shortenName(item.created_by_user.name)}
                                                </div>
                                            </td>
                                            <td>
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        {Array.isArray(item.task_assigns) && item.task_assigns.map((task_assign) => (
                                                            <td key={task_assign.id}>
                                                                <div title={task_assign.name}
                                                                     className="profile-avatar profile-avatar-reportado profile-avatar-table">
                                                                    {utilsHelper.shortenName(task_assign.name)}
                                                                </div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Activities;