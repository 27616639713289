import commonApiService from "./commonApiService.js";

const getActivities = async () => {
    try {
        const token = localStorage.getItem('access_token');
        const response = await commonApiService.apiPrivate().get('/tasks', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener clientes');
    }
};

const getActivity = async (taskId) => {
    try {
        const token = localStorage.getItem('access_token');
        const response = await commonApiService.apiPrivate().get(`/tasks/${taskId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener clientes');
    }
};

const activitiesService = {
    getActivities,
    getActivity
}

export default activitiesService;