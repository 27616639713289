import React, { useState, useEffect } from "react";
import statusService from "../../../services/statusService.js";

const ModalStatus = ({ isOpen, onClose, editingStatus, clientId, loadContent, colorPalette }) => {
    const [formData, setFormData] = useState({
        name: "",
        client_id: clientId,
        color: "#7f8c8d",
    });
    const [color, setColor] = useState('#7f8c8d');

    useEffect(() => {
        if (editingStatus) {
            setFormData({
                name: editingStatus.name || "",
                client_id: editingStatus.client_id || "",
                color: editingStatus.color || "#7f8c8d",
            });
        } else {
            setFormData({
                name: "",
                client_id: clientId,
                color: "#7f8c8d",
            });
        }
    }, [editingStatus]);

    const handleColorSelect = (selectedColor) => {
        setColor(selectedColor);
        formData.color = selectedColor;
        setFormData(formData);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const method = editingStatus ? "PUT" : "POST";
        const payload = {
            ...formData,
        };

        if (method === "PUT") {
            statusService.setStatus(editingStatus.id, payload).then(r => {
                loadContent();
                onClose();
            });
        }

        if (method === "POST") {
            statusService.createStatus(payload).then(r => {
                loadContent();
                onClose();
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <h2 className="modal-title">
                    {editingStatus ? "Editar Estado" : "Crear Estado"}
                </h2>
                <form onSubmit={handleSubmit}>
                    {clientId && (
                        <input type="hidden" name="client_id" value={formData.client_id}/>
                    )}
                    <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="color-picker">Selecciona un color para el estado </label>
                        <br/>
                        <div className="color-palette">
                            {colorPalette.map((colorValue) => (
                                <button
                                    className="color-palette-circle"
                                    type="button"
                                    key={colorValue}
                                    style={{
                                        backgroundColor: colorValue,
                                        border: colorValue === color ? '4px solid black' : '4px solid white'
                                    }}
                                    onClick={() => handleColorSelect(colorValue)}
                                />
                            ))}
                        </div>
                        <br/>
                        <label htmlFor="hex-value">Valor hexadecimal </label>
                        <input
                            type="text"
                            id="hex-value"
                            value={color}
                            readOnly
                        />
                    </div>

                    <button type="submit" className="btn btn-success">
                        <i className="bi bi-save me-2"></i>
                        {editingStatus ? "Actualizar" : "Crear"}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary ms-2"
                        onClick={onClose}>
                        <i className="bi bi-x-circle me-2"></i>
                        Cancelar
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ModalStatus;
